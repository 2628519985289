import './src/styles/global.css';
import './src/styles/markdown.css';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faChevronRight,
  faChevronLeft,
  faChevronDown,
  faExclamationCircle,
  faPhoneAlt,
  faBars,
  faMapMarkedAlt,
  faEnvelope,
  faAngleDoubleRight,
  faCheckCircle,
} from '@fortawesome/free-solid-svg-icons';
import {
  faFacebookF,
  faTwitter,
  faLinkedinIn,
  faInstagram,
  faSkype,
} from '@fortawesome/free-brands-svg-icons';

library.add(
  faChevronLeft,
  faChevronRight,
  faChevronDown,
  faExclamationCircle,
  faPhoneAlt,
  faBars,
  faMapMarkedAlt,
  faEnvelope,
  faAngleDoubleRight,
  faFacebookF,
  faTwitter,
  faLinkedinIn,
  faInstagram,
  faSkype,
  faCheckCircle
);
